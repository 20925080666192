import {Hotel} from "../../../data/BackendClasses";
import {Dialog} from "primereact/dialog";
import {formatDate, formHasErrors, isNumber} from "../../../lib/helpers";
import React, {useContext, useState} from "react";
import {useFormState} from "../../../data/Form";
import {postJsonToBackend} from "../../../data/network";
import {GlobalContext} from "../../../data/GlobalContext";
import {isReturnedErrors} from "../../../components/ErrorModal";
import {Calendar} from "primereact/calendar";


export default function EditHotelDialog(
    {
        showEditDialog,
        selectedHotel,
        onClose
    }:{
        showEditDialog: boolean,
        selectedHotel: Hotel,
        onClose: () => void
    }) {
    const {inputValues, handleInputChange, dirty} = useFormState({
        ...selectedHotel,
        profile: selectedHotel.profile ? selectedHotel.profile : '',
        propertyId: selectedHotel.propertyId ? selectedHotel.propertyId : '',
        category: selectedHotel.category ? selectedHotel.category : '',
        tax: selectedHotel.tax.toString(),
        taxPercent: selectedHotel.taxPercent.toString(),
        taxPerNight: selectedHotel.taxPerNight.toString(),
        taxPerNightPercent: selectedHotel.taxPerNightPercent.toString(),
        airbnbCom: selectedHotel.airbnbCom.toString(),
        rocketstayShare: selectedHotel.rocketstayShare.toString(),
        resortFee: selectedHotel.resortFee.toString(),
        resortFeePercent: selectedHotel.resortFeePercent.toString(),
        passThrough: selectedHotel.passThrough.toString(),
        lodgingTaxDaily: selectedHotel.lodgingTaxDaily.toString(),
        transferToNewFee: selectedHotel.transferToNewFee,
        endTransferToNewFee: selectedHotel.endTransferToNewFee,
    })

    const erori: { [x: string]: string[] } = {
        hotelName: [],
        hotelProfile: [],
        propertyId: [],
        category: [],
        tax: [],
        taxPercent: [],
        taxPerNight: [],
        taxPerNightPercent: [],
        airbnbCom: [],
        rocketstayShare: [],
        resortFee: [],
        resortFeePercent: [],
        passThrough: [],
        lodgingTaxDaily: [],
        transferToNewFee: [],
        endTransferToNewFee: []

    };
    const {showToastMessage} = useContext(GlobalContext);
    const [transferToNewFee, setTransferToNewFee] = useState<string | Date | Date[] | undefined | null>(selectedHotel.transferToNewFee);
    const [endTransferToNewFee, setEndTransferToNewFee] = useState<string | Date | Date[] | undefined | null>(selectedHotel.endTransferToNewFee);
    const [dataDirty, setDataDirty] = useState<boolean>(false);

    function editHotel(e: any){
        e.preventDefault();
        console.log("EDIT HOTEL ", inputValues);
        console.log("INITIAL HOTEL ", selectedHotel)

        postJsonToBackend("/hotels/edit", {...inputValues, transferToNewFee: transferToNewFee, endTransferToNewFee: endTransferToNewFee})
            .then(rsp => {
                showToastMessage("Success", "Hotel modified", "success");
                onClose();
            })
            .catch(err => {
                console.log("ERR ", err);
                if(isReturnedErrors(err)){
                    showToastMessage("Error", err.errors[0].message, "error");
                } else {
                    showToastMessage("Error", "Something went bad and the hotel was not modified", "error");
                }
            })
    }

    function hotelIsModified(){
        return inputValues.name != selectedHotel.name ||
            inputValues.profile != selectedHotel.profile ||
            inputValues.propertyId != selectedHotel.propertyId ||
            inputValues.category != selectedHotel.category ||
            inputValues.taxPercent != selectedHotel.taxPercent.toString() ||
            inputValues.airbnbCom != selectedHotel.airbnbCom.toString() ||
            inputValues.rocketstayShare != selectedHotel.rocketstayShare.toString() ||
            transferToNewFee != selectedHotel.transferToNewFee ||
            endTransferToNewFee != selectedHotel.endTransferToNewFee
    }

    function checkForFormErrors() {
        if (!inputValues.name && (dirty || dataDirty)) {
            erori.hotelName.push("Hotel name is mandatory");
        }

        if(inputValues.propertyId && !/^\d+$/.test(inputValues.propertyId)){
            erori.propertyId.push("Property Id should be a number");
        }

        if((dirty || dataDirty) && !(inputValues.propertyId.length > 0 || inputValues.profile.length > 0)){
            erori.propertyId.push("At least one of this fields is mandatory");
            erori.hotelProfile.push("At least one of this fields is mandatory");
        }

        if((dirty || dataDirty) && (inputValues.category != '') && !isNumber(inputValues.category)){
            erori.category.push("Category should be a numeric value");
        }

        if((dirty || dataDirty) && (inputValues.taxPercent != '') && !isNumber(inputValues.taxPercent)){
            erori.taxPercent.push("Tax Percent should be a numeric value");
        }

        if((dirty || dataDirty) && (inputValues.airbnbCom != '') && !isNumber(inputValues.airbnbCom)){
            erori.airbnbCom.push("Airbnb Com should be a numeric value");
        }

        if((dirty || dataDirty) && (inputValues.rocketstayShare != '') && !isNumber(inputValues.rocketstayShare)){
            erori.rocketstayShare.push("Rocketstay Share should be a numeric value");
        }

        if((dirty || dataDirty) && (transferToNewFee == undefined)){
            erori.transferToNewFee.push("Transfer to new fee is mandatory");
        }
    }

    checkForFormErrors();

    return <>
        <Dialog
            header={`Edit ${selectedHotel.name}`}
            visible={showEditDialog}
            style={{ width: '50vw'}}
            onHide={onClose}
            pt={{
                root: {className: "!shadow-2xl"},
                content: {className: "!bg-white/70  backdrop-blur-3xl"},
                header: {className: "!bg-white/70  backdrop-blur-3xl !text-[#644243]"},
                headerIcons: {className: "!text-white "},
                closeButton: {className: "!text-white "}
            }}
        >
            <div>
                <form onSubmit={editHotel}>
                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.hotelName.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="name">Full Name</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 ${erori.hotelName.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="name" id="name" type="text" value={inputValues.name} onChange={handleInputChange}/>
                        {erori.hotelName.length > 0 && <div className="text-red-500 text-sm">{erori.hotelName[erori.hotelName.length-1]}</div>}
                    </div>
                    <div className="mb-2">
                        <label className={`block mb-2 font-medium ${erori.hotelProfile.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="profile">Hotel Profile</label>
                        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.hotelProfile.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="profile" id="profile" type="text" value={inputValues.profile} onChange={handleInputChange}/>
                        {erori.hotelProfile.length > 0 && <div className="text-red-500 text-sm">{erori.hotelProfile[erori.hotelProfile.length-1]}</div>}
                    </div>

                    <div className="flex flex-row gap-2">
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.propertyId.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="propertyId">Property ID</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.propertyId.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="propertyId" id="propertyId" type="text" value={inputValues.propertyId} onChange={handleInputChange}/>
                            {erori.propertyId.length > 0 && <div className="text-red-500 text-sm">{erori.propertyId[erori.propertyId.length-1]}</div>}
                        </div>
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.category.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="category">Category</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.category.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="category" id="category" type="text" value={inputValues.category} onChange={handleInputChange}/>
                            {erori.category.length > 0 && <div className="text-red-500 text-sm">{erori.category[erori.category.length-1]}</div>}
                        </div>
                    </div>

                    <div className="flex flex-row gap-2">
                        {/*<div className="mb-2 w-1/2">*/}
                        {/*    <label className={`block mb-2 font-medium ${erori.tax.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="tax">Tax</label>*/}
                        {/*    <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.tax.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="tax" id="tax" type="text" value={inputValues.tax} onChange={handleInputChange}/>*/}
                        {/*    {erori.tax.length > 0 && <div className="text-red-500 text-sm">{erori.tax[erori.tax.length-1]}</div>}*/}
                        {/*</div>*/}
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.taxPercent.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="taxPercent">Tax %</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.taxPercent.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="taxPercent" id="taxPercent" type="text" value={inputValues.taxPercent} onChange={handleInputChange}/>
                            {erori.taxPercent.length > 0 && <div className="text-red-500 text-sm">{erori.taxPercent[erori.taxPercent.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.taxPerNight.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="taxPerNight">Hotel Tax/Night</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.taxPerNight.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="taxPerNight" id="taxPerNight" type="text" value={inputValues.taxPerNight} onChange={handleInputChange}/>
                            {erori.taxPerNight.length > 0 && <div className="text-red-500 text-sm">{erori.taxPerNight[erori.taxPerNight.length-1]}</div>}
                        </div>
                    </div>

                    {/*<div className="flex flex-row gap-2">*/}
                    {/*    */}
                    {/*    <div className="mb-2 w-1/2">*/}
                    {/*        <label className={`block mb-2 font-medium ${erori.taxPerNightPercent.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="taxPerNightPercent">Tax/Night %</label>*/}
                    {/*        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.taxPerNightPercent.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="taxPerNightPercent" id="taxPerNightPercent" type="text" value={inputValues.taxPerNightPercent} onChange={handleInputChange}/>*/}
                    {/*        {erori.taxPerNightPercent.length > 0 && <div className="text-red-500 text-sm">{erori.taxPerNightPercent[erori.taxPerNightPercent.length-1]}</div>}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="flex flex-row gap-2">
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.airbnbCom.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="airbnbCom">Airbnb Com %</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.airbnbCom.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="airbnbCom" id="airbnbCom" type="text" value={inputValues.airbnbCom} onChange={handleInputChange}/>
                            {erori.airbnbCom.length > 0 && <div className="text-red-500 text-sm">{erori.airbnbCom[erori.airbnbCom.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.rocketstayShare.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="rocketstayShare">Rocketstay Share %</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.rocketstayShare.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="rocketstayShare" id="rocketstayShare" type="text" value={inputValues.rocketstayShare} onChange={handleInputChange}/>
                            {erori.rocketstayShare.length > 0 && <div className="text-red-500 text-sm">{erori.rocketstayShare[erori.rocketstayShare.length-1]}</div>}
                        </div>
                    </div>

                    {/*<div className="flex flex-row gap-2">*/}
                    {/*    <div className="mb-2 w-1/2">*/}
                    {/*        <label className={`block mb-2 font-medium ${erori.resortFee.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="resortFee">Resort Fee</label>*/}
                    {/*        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.resortFee.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="resortFee" id="resortFee" type="text" value={inputValues.resortFee} onChange={handleInputChange}/>*/}
                    {/*        {erori.resortFee.length > 0 && <div className="text-red-500 text-sm">{erori.resortFee[erori.resortFee.length-1]}</div>}*/}
                    {/*    </div>*/}

                    {/*    <div className="mb-2 w-1/2">*/}
                    {/*        <label className={`block mb-2 font-medium ${erori.resortFeePercent.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="resortFeePercent">Resort Fee %</label>*/}
                    {/*        <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.resortFeePercent.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="resortFeePercent" id="resortFeePercent" type="text" value={inputValues.resortFeePercent} onChange={handleInputChange}/>*/}
                    {/*        {erori.resortFeePercent.length > 0 && <div className="text-red-500 text-sm">{erori.resortFeePercent[erori.resortFeePercent.length-1]}</div>}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="flex flex-row gap-2">
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.passThrough.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="passThrough">Pass Through % (rocket fee)</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.passThrough.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="passThrough" id="passThrough" type="text" value={inputValues.passThrough} onChange={handleInputChange}/>
                            {erori.passThrough.length > 0 && <div className="text-red-500 text-sm">{erori.passThrough[erori.passThrough.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.lodgingTaxDaily.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="lodgingTaxDaily">Lodging Tax Daily</label>
                            <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.resortFeePercent.length > 0 ? " border-solid border-red-500" : " border-none"}`} name="lodgingTaxDaily" id="lodgingTaxDaily" type="text" value={inputValues.lodgingTaxDaily} onChange={handleInputChange}/>
                            {erori.lodgingTaxDaily.length > 0 && <div className="text-red-500 text-sm">{erori.lodgingTaxDaily[erori.lodgingTaxDaily.length-1]}</div>}
                        </div>
                    </div>

                    <div className="flex flex-row">
                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.transferToNewFee.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="transferToNewFee">Transfer to new fee</label>
                            {/*<input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.transferToNewFee.length > 0 ? " border-solid border-red-500" : " border-none"}`} id="transferToNewFee" name="transferToNewFee" type="date" value={formatDate(selectedHotel.transferToNewFee)}  onChange={handleInputChange}/>*/}
                            <Calendar
                                name="transferToNewFee"
                                value={selectedHotel.transferToNewFee ? new Date(selectedHotel.transferToNewFee) : undefined}
                                onChange={
                                    (e) => {
                                        console.log("OLD TRANSFER FEE ", transferToNewFee);
                                        setTransferToNewFee(prevState => e.value)
                                        setDataDirty(prevState => true);
                                    }
                                }
                                pt={{root: {className: `${erori.transferToNewFee.length > 0 ? "border border-red-500 rounded-md" : ""}`}}}
                                view="date"
                            />
                            {erori.transferToNewFee.length > 0 && <div className="text-red-500 text-sm">{erori.transferToNewFee[erori.transferToNewFee.length-1]}</div>}
                        </div>

                        <div className="mb-2 w-1/2">
                            <label className={`block mb-2 font-medium ${erori.endTransferToNewFee.length > 0 ? "text-red-500" : "text-[#644243]"}`} htmlFor="endTransferToNewFee">End transfer to new fee</label>
                            {/*<input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white ${erori.transferToNewFee.length > 0 ? " border-solid border-red-500" : " border-none"}`} id="transferToNewFee" name="transferToNewFee" type="date" value={formatDate(selectedHotel.transferToNewFee)}  onChange={handleInputChange}/>*/}
                            <Calendar
                                name="endTransferToNewFee"
                                value={selectedHotel.endTransferToNewFee ? new Date(selectedHotel.endTransferToNewFee) : undefined}
                                onChange={
                                    (e) => {
                                        console.log("OLD END TRANSFER FEE ", endTransferToNewFee);
                                        setEndTransferToNewFee(prevState => e.value)
                                        setDataDirty(prevState => true);
                                    }
                                }
                                pt={{root: {className: `${erori.endTransferToNewFee.length > 0 ? "border border-red-500 rounded-md" : ""}`}}}
                                view="date"
                            />
                            {erori.endTransferToNewFee.length > 0 && <div className="text-red-500 text-sm">{erori.endTransferToNewFee[erori.endTransferToNewFee.length-1]}</div>}
                        </div>
                    </div>

                    <div className="w-full text-center">
                        {!formHasErrors(erori) && (dirty || dataDirty) && hotelIsModified() && <button
                            type="submit"
                            className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 px-2 py-1"
                        >
                            Edit
                        </button>}
                    </div>
                </form>
            </div>
        </Dialog>
    </>


}