import {FileUpload, FileUploadHandlerEvent} from "primereact/fileupload";
import {useContext, useRef, useState} from "react";
import {postJsonFileToBackendThenGetFile} from "../../../data/network";
import {GlobalContext} from "../../../data/GlobalContext";


export default function ExtractBasePricePage() {

    const fileUploadRef = useRef<any>(null);
    const {showToastMessage} = useContext(GlobalContext);
    const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);

    function uploadHandler(event: FileUploadHandlerEvent){

        setUploadInProgress(prevState => true);

        const formData = new FormData();
        formData.append('data', event.files[0]);

        postJsonFileToBackendThenGetFile("/extract-base-price/upload", formData)
            .then(rsp => {
                console.log("FILE UPLOADED RSP ", rsp);

                //@ts-ignore
                const url = window.URL.createObjectURL(rsp);
                const a = document.createElement('a');
                a.href = url;
                a.download = event.files[0].name;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);

                fileUploadRef.current.clear();
                setUploadInProgress(prevState => false);

            })
            .catch(err => {
                console.log("ERR ", err)
                showToastMessage("Error", "Something went wrong...", "error");
                setUploadInProgress(prevState => false);
            })
    }


    return <div id="csv-loader-page" className="flex flex-col items-center justify-center w-full gap-2 h-full">
        <div className="w-full md:w-1/2 lg:w-1/2 p-6 bg-white/70 backdrop-blur rounded-xl flex flex-col items-center gap-3">
            <FileUpload
                ref={fileUploadRef}
                // mode="basic"
                // auto
                name="data"
                accept=".csv"
                customUpload
                uploadHandler={uploadHandler}
                chooseLabel={"Choose file"}
                emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>}
                className="!w-full"
                disabled={uploadInProgress}
            />
        </div>


    </div>

}