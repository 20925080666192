import {Hotel} from "../../../data/BackendClasses";
import {Dialog} from "primereact/dialog";
import React from "react";
import {Calendar} from "primereact/calendar";


export default function HotelDetailsDialog(
    {
        showDetailsDialog,
        selectedHotel,
        onClose
    }:{
        showDetailsDialog: boolean,
        selectedHotel: Hotel,
        onClose: () => void
    }) {


    return <Dialog
        header={`Details ${selectedHotel.name}`}
        visible={showDetailsDialog}
        style={{ width: '50vw'}}
        onHide={onClose}
        pt={{
            root: {className: "!shadow-2xl"},
            content: {className: "!bg-white/70  backdrop-blur-3xl"},
            header: {className: "!bg-white/70  backdrop-blur-3xl !text-[#644243]"},
            headerIcons: {className: "!text-white "},
            closeButton: {className: "!text-white "}
        }}
    >

        <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2">
                {/*<div className="flex flex-col w-1/4">*/}
                {/*    <label className={`block mb-2 font-medium text-[#644243]`}>Tax</label>*/}
                {/*    <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white`} type="text" value={selectedHotel.tax} disabled={true}/>*/}
                {/*</div>*/}
                <div className="flex flex-col w-1/2">
                    <label className={`block mb-2 font-medium text-[#644243]`}>Tax %</label>
                    <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white`} type="text" value={selectedHotel.taxPercent} disabled={true}/>
                </div>
                <div className="flex flex-col w-1/2">
                    <label className={`block mb-2 font-medium text-[#644243]`}>Hotel Tax/Night</label>
                    <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white`} type="text" value={selectedHotel.taxPerNight} disabled={true}/>
                </div>
                {/*<div className="flex flex-col w-1/4">*/}
                {/*    <label className={`block mb-2 font-medium text-[#644243]`}>Tax/Night %</label>*/}
                {/*    <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white`} type="text" value={selectedHotel.taxPerNightPercent} disabled={true}/>*/}
                {/*</div>*/}
            </div>
            <div className="flex flex-row gap-2">
                <div className="flex flex-col w-1/2">
                    <label className={`block mb-2 font-medium text-[#644243]`}>Airbnb com %</label>
                    <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white`} type="text" value={selectedHotel.airbnbCom} disabled={true}/>
                </div>
                <div className="flex flex-col w-1/2">
                    <label className={`block mb-2 font-medium text-[#644243]`}>Rocketstay Share %</label>
                    <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white`} type="text" value={selectedHotel.rocketstayShare} disabled={true}/>
                </div>
                {/*<div className="flex flex-col w-1/4">*/}
                {/*    <label className={`block mb-2 font-medium text-[#644243]`}>Resort Fee</label>*/}
                {/*    <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white`} type="text" value={selectedHotel.resortFee} disabled={true}/>*/}
                {/*</div>*/}
                {/*<div className="flex flex-col w-1/4">*/}
                {/*    <label className={`block mb-2 font-medium text-[#644243]`}>Resort Fee %</label>*/}
                {/*    <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white`} type="text" value={selectedHotel.resortFeePercent} disabled={true}/>*/}
                {/*</div>*/}
            </div>
            <div className="flex flex-row gap-2">
                <div className="flex flex-col w-1/2">
                    <label className={`block mb-2 font-medium text-[#644243]`}>Pass Through % (rocket fee)</label>
                    <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white`} type="text" value={selectedHotel.passThrough} disabled={true}/>
                </div>
                <div className="flex flex-col w-1/2">
                    <label className={`block mb-2 font-medium text-[#644243]`}>Lodging Tax Daily</label>
                    <input className={`custom-form w-full text-gray-900 rounded-lg block 2.5 bg-white`} type="text" value={selectedHotel.lodgingTaxDaily} disabled={true}/>
                </div>
            </div>

            <div className="flex flex-row gap-2">
                <div className="flex flex-col w-1/2">
                    <label className={`block mb-2 font-medium text-[#644243]`}>Transfer to new fee </label>
                    <Calendar disabled={true} value={selectedHotel.transferToNewFee ? new Date(selectedHotel.transferToNewFee) : undefined}/>
                </div>

                <div className="flex flex-col w-1/2">
                    <label className={`block mb-2 font-medium text-[#644243]`}>End transfer to new fee </label>
                    <Calendar disabled={true} value={selectedHotel.endTransferToNewFee ? new Date(selectedHotel.endTransferToNewFee) : undefined}/>
                </div>
            </div>


        </div>

    </Dialog>




}
